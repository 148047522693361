$(document).ready(function(){
	$('.discoveredThanksToChart').each(function(){
    var ctx = $(this);
    var stats = ctx.data('stats');
    var titlesColors = [];
    var firstColors = [
      "rgba(231, 76, 60,1.0)",
      "rgba(241, 196, 15,1.0)",
      "rgba(52, 152, 219,1.0)",
      "rgba(149, 165, 166,1.0)",
      "rgba(46, 204, 113,1.0)",
      "rgba(230, 126, 34,1.0)",
      "rgba(26, 188, 156,1.0)",
      "rgba(189, 195, 199,1.0)"
    ]
    $.each(Object.keys(stats), function( title ){
      titlesColors.push( 'rgba(' + Math.floor( Math.random() * (255 - 0) + 0 ) + ', ' + Math.floor( Math.random() * (255 - 0) + 0 ) + ', ' + Math.floor( Math.random() * (255 - 0) + 0 ) + ', 1)' );
    });
    titlesColors = firstColors.concat( titlesColors );
    var discoveredThanksToChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: Object.keys(stats),
        datasets: [{
            label: 'Clients',
            data: Object.values(stats),
            fillRect: 'rgba(52,152,219,1)',
            backgroundColor: titlesColors,
            // borderColor: [
            //     'rgba(255,99,132,1)',
            //     'rgba(54, 162, 235, 1)',
            //     'rgba(255, 206, 86, 1)',
            //     'rgba(75, 192, 192, 1)',
            //     'rgba(153, 102, 255, 1)',
            //     'rgba(255, 159, 64, 1)',
            //     'rgba(225, 159, 64, 1)'
            // ],
            borderWidth: 0
        }],
        showTooltips: false
      },
      options: {

      }
    });
  });


  window.updateWeekAttendanceCharts = function( $chart ){
  // function updateWeekAttendanceCharts( $chart ){
    var ctx = $chart;
    var stats = ctx.data('stats');
    var weekFrequentationChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Object.keys(stats),
        datasets: [{
          label: ' enfants',
          data: Object.values(stats),
          fillRect: 'rgba(52,152,219,1)',
          backgroundColor: [
            'rgba(52,152,219,1)',
            // 'rgba(54, 162, 235, 0.2)',
            // 'rgba(255, 206, 86, 0.2)',
            // 'rgba(75, 192, 192, 0.2)',
            // 'rgba(153, 102, 255, 0.2)',
            // 'rgba(255, 159, 64, 0.2)',
            // 'rgba(255, 129, 64, 0.2)'
          ],
          // borderColor: [
          //     'rgba(255,99,132,1)',
          //     'rgba(54, 162, 235, 1)',
          //     'rgba(255, 206, 86, 1)',
          //     'rgba(75, 192, 192, 1)',
          //     'rgba(153, 102, 255, 1)',
          //     'rgba(255, 159, 64, 1)',
          //     'rgba(225, 159, 64, 1)'
          // ],
          borderWidth: 0
        }],
        showTooltips: false
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              fixedStepSize: 10,
              beginAtZero: true,
              max: Math.max( ...Object.values(stats) ) + 5
            }
          }]
        },
        legend: {
          // display: false,
          onClick: function(e){
            e.preventDefault();
          }
        },
        tooltips: {
          enabled: false
        },
        elements: {
          rectangle: {
            backgroundColor: 'rgba(52,152,219,1)'
          }
        },
        hover: {animationDuration: 0},
        animation: {
          onComplete: function(){
            var ctx = this.chart.ctx;
            // ctx.font = this.scale.font;
            // ctx.fillStyle = this.scale.textColor
            ctx.fillStyle = "#000000";
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function (dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                if( dataset.data[i] > 0 ){
                  var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                  ctx.fillText(dataset.data[i], model.x, model.y - 5);
                }
              }
            })
          }
        }
      }
    });
    return weekFrequentationChart;
  }

  $(".weekAttendanceChart").each(function(){
    updateWeekAttendanceCharts( $(this) );
  });
});