$(document).ready(function() {
	$("#email_form").on('submit', function(e){
		let $form = $(this);
		datatables[0].api.rows( {selected: true} ).nodes().each(function(currentRow){
        	$form.append(`<input type="hidden" name="recipients_ids[]" value="${currentRow.dataset.accompanistId}">`);
		});
	});

	$("#visit_form").on('submit', function(e){
		let $form = $(this);
		datatables[0].api.rows( {selected: true} ).nodes().each(function(currentRow){
    		$form.append(`<input type="hidden" name="visits[${currentRow.dataset.rowKey}][accompanist_id]" value="${currentRow.dataset.accompanistId}">`);
        	$form.append(`<input type="hidden" name="visits[${currentRow.dataset.rowKey}][child_id]" value="${currentRow.dataset.childId}">`);
        	$form.append(`<input type="hidden" name="visits[${currentRow.dataset.rowKey}][range]" value="` + $form.find('.visits_ranges').val() + `">`);
        	$form.append(`<input type="hidden" name="visits[${currentRow.dataset.rowKey}][arrival_at]" value="` + $form.find('input[name="arrival_at"]').val() + `">`);
		});
	});
});