$(document).ready(function(){
  $(document).on('click', 'button[form]', function(e){
    $button = $(this);
    var number_of_visits = prompt("Indiquez le nombe de visites", 1);
    if( number_of_visits > 0 ){
      $form = $('form#' + $button.attr('form') );

      form_data = $form.serializeArray();
      form_data.push( { name: "ajax", value: true} );
      form_data.push( { name: "number_of_visits", value: number_of_visits} );
      form = {
        url: $form.attr('action'),
        method: $form.attr('method'),
        data: form_data
      };

      $('.loading').removeClass('hide');
      $.ajax(form.url, {
        data: form.data,
        method: form.method,
        success : function(response, status){
          var new_visits = response.new_visits;
          var current_week_visits_stats = response.current_week_visits_stats;
          datatables.forEach(function(datatable){
            if( datatable.html.attr('id') == 'visits_list' ){
              new_visits.forEach(function(visit){
              //ligne ajoutée lors de l'ajout d'une visite en Ajax
              var $tmp_row =
                $( `<tr data-visit-id="${visit.id}" data-child-id="" data-accompanist-id="${visit.accompanist.id}" role="row" class="even">
                  <td>${ visit.id }</td>
                  <td class="sorting_1">${ moment( visit.arrival_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }</td>
                  <td>${ visit.accompanist.name + ' ' + visit.accompanist.first_name }</td>
                  <td><span>${ visit.range == -3 ? 'Moins de 3 ans' : '3 ans et plus' }</span></td>
                  <td>${ visit.accompanist.phone }</td>
                  <td class="actions">
                    <a href="/visits/${ visit.id }" title="Supprimer la visite" data-token="${ Laravel.csrfToken }" data-method="DELETE" data-confirm="Voulez vous vraiment supprimer cette visite ?" class="btn btn-default btn-lg">
                      <span class="fa-stack fa-lg">
                      <i class="fa fa-trash fa-stack-1x"></i>
                      <i class="fa fa-stack-2x"></i>
                      </span>
                    </a>
                    <a href="/customers/${ visit.accompanist.id }" title="Consulter la fiche de ${ visit.accompanist.name + ' ' + visit.accompanist.first_name }" class="btn btn-default btn-lg">
                      <span class="fa-stack fa-lg">
                      <i class="fa fa-stack-2x"></i>
                      <i class="fa fa-info-circle fa-stack-1x"></i>
                      </span>
                    </a>
                  </td>
                </tr>`);

                datatable.api.row.add( $tmp_row ).draw();
                datatable.api.column('.visit_id').order('desc').draw();
                $tmp_row.effect("highlight", {}, 20000);

                $('#currentWeekAttendanceChart').data('stats', current_week_visits_stats);
                $(".weekAttendanceChart").each(function(){
                  window.updateWeekAttendanceCharts( $(this) );
                });
              });
              // modal is defined in app.blade.php
              var modal_title;
              var modal_content;
              // console.log( new_visits );
              if( new_visits.length > 1 ){
                modal_title = new_visits.length + " nouvelles visites ajoutées";
              }
              else{
                modal_title = "Une nouvelle visite ajoutée";
              }

              modal_title += " pour " + ( new_visits[0].accompanist.civility == 'Mrs' ? 'Mme' : 'M.' ) + ` ` + new_visits[0].accompanist.name + ` ` + new_visits[0].accompanist.first_name;

              const under_three_total_visits = new_visits[new_visits.length - 1].accompanist.under_three_visits;
              let under_three_total_visits_before_new_visits = under_three_total_visits;
              let under_three_new_visits = '';
              const under_three_before_free_countdown = new_visits[new_visits.length - 1].accompanist.under_three_before_free_countdown > 0 ? new_visits[new_visits.length - 1].accompanist.under_three_before_free_countdown : '<div class="alert alert-success" title="La prochaine visite sera gratuite">Proch. visite gratuite</div>';

              const three_and_more_total_visits = new_visits[new_visits.length - 1].accompanist.three_and_more_visits;
              let three_and_more_total_visits_before_new_visits = three_and_more_total_visits;
              let three_and_more_new_visits = '';
              const three_and_more_before_free_countdown = new_visits[new_visits.length - 1].accompanist.three_and_more_before_free_countdown > 0 ? new_visits[new_visits.length - 1].accompanist.three_and_more_before_free_countdown : '<div class="alert alert-success" title="La prochaine visite sera gratuite">Proch. visite gratuite</div>';
              if( new_visits[0].range == '-3' ){
                under_three_total_visits_before_new_visits = under_three_total_visits - new_visits.length;
                under_three_new_visits = '<span class="label label-success">+' + new_visits.length + '</span>';
              }
              else{
                three_and_more_total_visits_before_new_visits = three_and_more_total_visits - new_visits.length;
                three_and_more_new_visits = '<span class="label label-success">+' + new_visits.length + '</span>';
              }

              modal_content = 
                `<table>
                  <thead>
                    <tr>
                      <th colspan="2" class="under_three">
                        <span class="fa-stack fa-lg">
                          <i class="fa fa-stack-1x"></i>
                          <i class="fa fa-child fa-stack-1x "></i>
                        </span>
                      </th>
                      <th colspan="2" class="three_and_more">
                        <span class="fa-stack fa-lg">
                          <i class="fa fa-stack-2x"></i>
                          <i class="fa fa-child fa-stack-2x"></i>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Nb visites</td>
                      <td>Gratuit dans</td>
                      <td>Nb visites</td>
                      <td>Gratuit dans</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="visits_indicator">
                      <td class="under_three">
                        <span class="total_visits">${under_three_total_visits_before_new_visits}</span>
                        <span class="new_visits">${under_three_new_visits}</span>
                      </td>
                      <td class="under_three before_free_countdown">${under_three_before_free_countdown}</span>
                      </td>
                      <td class="three_and_more">
                        <span class="total_visits">${three_and_more_total_visits_before_new_visits}</span>
                        <span class="new_visits">${three_and_more_new_visits}</span>
                      </td>
                      <td class="three_and_more before_free_countdown">${three_and_more_before_free_countdown}</td>
                    </tr>
                  </tbody>
                </table>
                ${response.message}`;

              new_visits.forEach(function(new_visit){
                $('#potential_visitors_list *[data-accompanist-id="' + new_visit.accompanist.id + '"] .under_three_before_free_countdown').html( new_visit.accompanist.under_three_before_free_countdown > 0 ? new_visit.accompanist.under_three_before_free_countdown : '<div class="alert alert-success" title="La prochaine visite sera gratuite">Proch. visite gratuite</div>' );
                $('#potential_visitors_list *[data-accompanist-id="' + new_visit.accompanist.id + '"] .three_and_more_before_free_countdown').html( new_visit.accompanist.three_and_more_before_free_countdown  > 0 ? new_visit.accompanist.three_and_more_before_free_countdown : '<div class="alert alert-success" title="La prochaine visite sera gratuite">Proch. visite gratuite</div>' );
              });

              $("#message_modal .modal-title").text(modal_title);
              $("#message_modal .modal-body").html(modal_content);
              $("#message_modal").modal();
            }
          });
        },
        error : function(response, status, error){
          $.notify({
            // options
            message: 'Une erreur est survenue. La visite n\'a peut être pas été enregistrée. Essayez de réactualiser la page.'
          },{
            // settings
            type: 'danger'
          });
        },
        complete : function(response, status){
          $('.loading').addClass('hide');
        }
      });
    }
    e.preventDefault();
  });
});