$(document).ready(function() {

  //change automatiquement la ville sélectionnée lors du changement de code postal
  $(document).on('change', 'select[name="zipcode"]', function(){
    var zipcode = $(this).val();
    var cityFieldOptions = $('select[name="city"] option');
    cityFieldOptions.each(function(){
      $(this).removeAttr('selected');
      if( $(this).data('zipcode') == zipcode ){
        $(this).attr('selected', 'selected');
      }
    });
    cityFieldOptions.closest('select').effect('highlight', 1000);
  });

  //change automatiquement le code postal lors du changement de la ville
  $(document).on('change', 'select[name="city"]', function(){
    var city = $(this).val();
    var zipcodeFieldOptions = $('select[name="zipcode"] option');
    zipcodeFieldOptions.each(function(){
      $(this).removeAttr('selected');
      if( $(this).data('city') == city ){
        $(this).attr('selected', 'selected');
      }
    });
    zipcodeFieldOptions.closest('select').effect('highlight', 1000);
  });

  //ajoute les variables disponibles dans les emails lorsqu'on clique sur les boutons de variable
  $(document).on('click', '.insertable', function(e){
    e.preventDefault();
    $textarea = $(this).nextAll('.form-group').first().find('textarea');
    $textarea.val( $textarea.val() + $(this).text() ).focus();
  });

  //ajout la fonctionnalité "sélectionner tout" dans les tableaux Datatables
  $(document).on('click', '.datatable_toolbar .select-all', function(){
    if( $(this).attr('selected') == 'selected' ){
      $(this).removeAttr('selected');
      datatables[$(this).data('table-key')].api.rows().deselect();;
    }
    else{
      $(this).attr('selected', 'selected');
      datatables[$(this).data('table-key')].api.rows().select();
    }
  });

  $('td select').on('click', function(e){
    e.stopPropagation();
  });

  //********************************************************************************************
  // send searches data to controller in order to reload them when page change after submit

  // $(document).on('click', 'button', function(e){
  //   var $button = $(this);
  //   var form_attribute = $button.attr('form');
  //   if( typeof form_attribute !== typeof undefined ){
  //     var $table = $button.closest('table');
  //     var $concerned_form = $( '#' + $button.attr('form') );
  //     $table.find('thead tr.search input, thead tr.search select').each(function(){
  //       var $field = $(this);
  //       $concerned_form.append('<input type="hidden" name="current_researches[][' + $field.attr('placeholder').slugify() + '] value="' + $field.val() + '">');
  //     });
  //   }
  // });

  //********************************************************************************************

} );