$(document).ready(function(){
	String.prototype.ucfirst = function () {
		let str = this;
		str += '';
		var f = str.charAt(0).toUpperCase();
		return f + str.substr(1);
	};

	String.prototype.addSlashes = function() {
		let str = this;
		str = str.replace(/\\/g, '\\\\');
		str = str.replace(/\'/g, '\\\'');
		str = str.replace(/\"/g, '\\"');
		str = str.replace(/\0/g, '\\0');
		return str;
	}
   
	String.prototype.stripSlashes = function() {
		let str = this;
		str = str.replace(/\\'/g, '\'');
		str = str.replace(/\\"/g, '"');
		str = str.replace(/\\0/g, '\0');
		str = str.replace(/\\\\/g, '\\');
		return str;
	}

	String.prototype.slugify = function(){
	    return this.toString().toLowerCase()
	      .replace(/\s+/g, '-')           // Replace spaces with -
	      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
	      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
	      .replace(/^-+/, '')             // Trim - from start of text
	      .replace(/-+$/, '');            // Trim - from end of text
  }
});