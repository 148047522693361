window.$ = window.jQuery = require('jquery');
window.$.UI = window.jQuery.UI = require('jquery-ui-bundle');
window.Chart = require('chart.js');
window.$.notify = require('bootstrap-notify');
require('./Chart.BarFunnel.min');

require('bootstrap-sass');
window.moment = require('moment');
require('bootstrap-datepicker');
require('./bootstrap-datetimepicker');

// require('bootstrap-datepicker/js/locales/bootstrap-datepicker.fr.js');
require('datatables.net');
require('datatables.net-autofill');
require('datatables.net-autofill-bs');
require('datatables.net-bs');
require('datatables.net-buttons');
require('datatables.net-buttons-bs');

require( 'datatables.net-buttons/js/buttons.colVis.js' )(); // Column visibility
require( 'datatables.net-buttons/js/buttons.html5.js' )();  // HTML 5 file export
require( 'datatables.net-buttons/js/buttons.flash.js' )();  // Flash file export
require( 'datatables.net-buttons/js/buttons.print.js' )();  // Print view button
require( 'jszip' );
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


require('datatables.net-colreorder');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedheader');
require('datatables.net-keytable');
require('datatables.net-responsive');
require('datatables.net-responsive-bs');
require('datatables.net-scroller');
require('datatables.net-select');

// Turbolinks = require('turbolinks');

// Turbolinks.start();

// var $script = require("scriptjs");
var $script = require("./bootstrap-notify.min.js");
// $script("//cdnjs.cloudflare.com/ajax/libs/mouse0270-bootstrap-notify/3.1.7/bootstrap-notify.min.js", function() {
// });

require('./useful_functions.js');
require('./datatables_settings.js');
require('./update_forms_before_send.js');
require('./charts_settings.js');
require('./ajax_visits_management.js');
require('./scripts.js');