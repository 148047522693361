$(document).ready(function(){
  //les champs portant ces noms seront de type "select"
  var selectFieldsClassnames = [
    'relationship',
    'civility',
    'gender',
    'accept_emails',
    'discovered_thanks_to',
    'enabled',
    'range',
    'actions'
  ];
  //les champs portant ces noms seront de type "datetime"
  var datetimeFieldsClassnames = [
    'arrival_at',
    'send_at'
  ];
  //les champs portant ces noms seront de type "date"
  var dateFieldsClassnames = ['birthday'];

  // document.querySelectorAll(".sortable_table").forEach(function(currentTable){
  //   window.tmpHtml = '<tr class="search">';
  //   currentTable.querySelectorAll("thead th").forEach(function(currentTh){
  //     let title = currentTh.textContent;
  //     window.tmpHtml += '<th><input type="text" class="form-control" placeholder="'+title+'" /></th>';
  //   });
  //   window.tmpHtml += '</tr>';
  //   // debugger;
  //   currentTable.querySelector('thead').prepend( document.createRange().createContextualFragment(window.tmpHtml) );
  // });

  $('.sortable_table').each(function(){
    window.tmpHtml = '<tr class="search">';
    $(this).find('thead th').each( function () {
      var title = $(this).text();
      window.tmpHtml +='<th>';
      window.tmpHtml += '<input type="text" class="form-control" placeholder="'+title+'" />';
      window.tmpHtml +='</th>';
    } );
    window.tmpHtml += '</tr>';
    $(this).find('thead').prepend(window.tmpHtml);
  });

  function classMatch(fieldsClassnames, $element){
    var returnValue = false;
    $(fieldsClassnames).each(function(){
      classname = this.valueOf();
      if( $element.hasClass( classname ) ){
        returnValue = classname;
        return false;
      }
    });
    return returnValue;
  }

  $.fn.dataTable.moment = function ( format, locale ) {
    var types = $.fn.dataTable.ext.type;
   
    // Add type detection
    types.detect.unshift( function ( d ) {
      if( d.length < 16 ){
          if ( d ) {
            // Strip HTML tags and newline characters if possible
            if ( d.replace ) {
              d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
            }

            // Strip out surrounding white space
            d = $.trim( d );
          }

          // Null and empty values are acceptable
          if ( d === '' || d === null ) {
            return 'moment-'+format;
          }

          return moment( d, format, locale, true ).isValid() ?
            'moment-'+format :
            null;
      }
    } );
 
    // Add sorting method - use an integer for the sorting
    types.order[ 'momentDate-pre' ] = function ( d ) {
      if ( d ) {
        // Strip HTML tags and newline characters if possible
        if ( d.replace ) {
            d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
        }

        // Strip out surrounding white space
        d = $.trim( d );
      }

      return d === '' || d === null ?
        -Infinity :
        parseInt( moment( d, format, locale, true ).format( 'x' ), 10 );
    };
  }

  $.fn.dataTable.moment( 'YYYY-MM-DD HH:mm' );

  window.datatables = [];
  $('.sortable_table').each(function(key, concernedTable){

    $(concernedTable).find('tr.titles th').each(function(thKey){
      if( classMatch( datetimeFieldsClassnames, $(this) ) ){
        currentClassname = classMatch( datetimeFieldsClassnames, $(this) );
        /* Custom filtering function which will search data in column n° thKey between two datetimes */
        $.fn.dataTable.ext.search.push(
          function( settings, data, dataIndex ) {
            var beginning = moment( $('#table' + key + currentClassname.ucfirst() + thKey + 'BeginningDatetime').val(), 'YYYY-MM-DD HH:mm' );
            var ending = moment( $('#table' + key + currentClassname.ucfirst() + thKey + 'EndingDatetime').val(), 'YYYY-MM-DD HH:mm' );
            var date = data[thKey];
            var date = moment(date, 'YYYY-MM-DD HH:mm');
            if( ( beginning == undefined || ending == undefined ) ||
                ( !beginning.isValid() || !ending.isValid() ) ||
                ( date.isBetween(beginning, ending) )
            ){
              return true;
            }
            return false;
          }
        );
      }
      else if( classMatch( dateFieldsClassnames, $(this) ) ){
        currentClassname = classMatch( dateFieldsClassnames, $(this) );
        /* Custom filtering function which will search data in column n° thKey between two dates */
        $.fn.dataTable.ext.search.push(
          function( settings, data, dataIndex ) {
            if( $(concernedTable).attr('id') == settings.sTableId ){
              var beginning = moment( $('#table' + key + currentClassname.ucfirst() + thKey + 'BeginningDate').val(), 'YYYY-MM-DD' );
              var ending = moment( $('#table' + key + currentClassname.ucfirst() + thKey + 'EndingDate').val(), 'YYYY-MM-DD' );
              var date = moment(data[thKey], 'YYYY-MM-DD');
  
              if( ( beginning == undefined || ending == undefined ) ||
                  ( !beginning.isValid() || !ending.isValid() ) ||
                  ( date.isBetween(beginning, ending) )
              ){
                return true;
              }
              return false;
            }
            return true;
          }
        );
      }
    });
    
    $(concernedTable).DataTable({
      language: {
        url: "/lang/fr/dataTables.french.lang",
        processing: "Chargement...",
        buttons: {
          copy: "Copier",
        }
      },
      processing: true,
      // paging: false,
      lengthMenu: [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "Tout afficher"] ],
      dom: '<"top"Biflp<"clear"><"datatable_toolbar">>rt<"bottom"iflp<"clear">>',
      select: true,
      buttons: [
            'copy', 'csv', 'excel', 'pdf'
      ],
      order: [
        // [ 0, 'asc' ],
        // [ 1, 'asc' ]
      ],
      colReorder: {
        realtime: true
      },
      columnDefs: [
        { targets: "actions", "orderable": false, "searchable": false },
        // { targets: ["birthday", "arrival_at"], "type": "date" },
        { targets: "arrival_at", "type": "moment-YYYY-MM-DD HH:mm-pre" },
      ],
      fnInitComplete: function(){
        window.datatables[key] = {};
        window.datatables[key].html = $datatable = this;
        window.datatables[key].api = $api = $datatable.api();
        $api.select.style('multi+shift');
        $api.columns().every( function () {
          var column = this;
          var columnHeader = column.header();
          var columnTitle = $(columnHeader).text();
          var columnIndex = $(columnHeader).parent().children().index(column.header());
          var $columnInputContainer = $($(this.header()).parent().siblings('.search').children()[columnIndex]);

          if( [ 'visits_list'].indexOf( $datatable.attr('id') ) >= 0 ){
            $api.column('.visit_id').order('desc').draw();
            $api.column('.visit_id').visible( false ).draw();
            $api.column('.arrival_at').order('desc').draw();
            $api.column('.arrival_time').order('desc').draw();
          }
          else{
            $api.column('.accompanist').order('asc').draw();
          }

          if( classMatch( selectFieldsClassnames, $(columnHeader) ) ){
            var $selectField = $('<select class="form-control"><option value=""></option></select>');
            if(columnTitle == 'Actions'){
              const massiveActionForm = '<form method="POST" class="ids-form"></form>';
              $selectField.addClass('Actions')
                          .attr('data-id', "actions");
              $(column.data()[0]).each(function(){
                if( $(this).is('a') ){
                  $link = $(this);
                  linkHref = $link.attr('href');

                  // if( linkHref.search(/.*\/visits\/\d+\/destroy/i) >= 0 && $link.data('method') == 'DELETE' ){
                  if( linkHref.search(/.*\/visits\/\d+/i) >= 0 && $link.data('method') == 'DELETE' ){
                    $selectField.append('<option data-href="' + visits_mass_destroy_route + '" data-method="DELETE">Supprimer les visites sélectionnées</option>');
                  }
                  else if(  linkHref.search(/.*\/children\/\d+\/visit/i) >= 0 ||
                            linkHref.search(/.*\/children\/\d+\/accompanists\/\d+\/visit/i) >= 0 ){
                    $selectField.append('<option data-href="' + visits_mass_store_route + '">Valider la visite des enfants sélectionnées</option>');
                  }
                  else if( (linkHref.search(/.*\/children\/\d+$/i) >= 0) && $link.data('method') == 'DELETE' ){
                    $selectField.append('<option data-href="' + children_mass_destroy_route + '" data-method="DELETE">Supprimer les fiches sélectionnées</option>');
                  }
                  else if( (linkHref.search(/.*\/customers\/\d+$/i) >= 0) && $link.data('method') == 'DELETE' ){
                    $selectField.append('<option data-href="' + customers_mass_destroy_route + '" data-method="DELETE">Supprimer les fiches sélectionnées</option>');
                  }
                  else if( (linkHref.search(/.*\/customers\/\d+\/children\/\d+\/link/i) >= 0) ){
                    $selectField.append('<option data-href="' + children_accompanists_link_route + '">Lier les fiches sélectionnées</option>');
                  }else if( (linkHref.search(/.*\/children\/\d+\/customers\/\d+\/link/i) >= 0) ){
                    $selectField.append('<option data-href="' + children_accompanists_link_route + '">Lier les fiches sélectionnées</option>');
                  }
                  else if( (linkHref.search(/.*\/customers\/\d+\/children\/\d+\/unlink/i) >= 0) ){
                    $selectField.append('<option data-href="' + children_accompanists_unlink_route + '">Dissocier les fiches sélectionnées</option>');
                  }
                  else if( (linkHref.search(/.*\/children\/\d+\/customers\/\d+\/unlink/i) >= 0) ){
                    $selectField.append('<option data-href="' + children_accompanists_unlink_route + '">Dissocier les fiches sélectionnées</option>');
                  }
                }
              });

              $selectField.on('change', function(e){
                const $select = $(this);
                const $selectedOption = $select.find(':selected');
                const selectedAction = $selectedOption.data('href');
                const $form = $select.siblings('form').first();
                const method = $selectedOption.data('method');
                let confirmAction = true;
                if( method != undefined ){
                  if(method == 'DELETE') confirmAction = confirm('Êtes vous sur de vouloir supprimer ces élements?');
                  if(confirmAction){
                    $form.empty();
                    $form.append('<input type="hidden" name="_token" value="' + window.Laravel.csrfToken + '">');
                    $form.append('<input type="hidden" name="_method" value="' + method + '">');
                    // $form.append('<input type="hidden" name="ajax" value="1">');
                    const $selectedRows = $api.rows( { 'selected': true } ).nodes().to$();
                    //selectionne tous les ids sur le lignes de tableau pour les envoyer aux controllers
                    $selectedRows.each(function(){
                      let current_id = this.dataset[ $form.parents('table').attr('id').replace('s_list', 'Id') ];
                      $form.append(`<input type="hidden" name="ids[]" value="${current_id}">`);
                    });
                    $form.attr('action', selectedAction);
                    $form.submit();
                  }
                }
              });
              $columnInputContainer.empty().append( massiveActionForm );
              $columnInputContainer.append( $selectField );
            }
            else{
              column.data().unique().sort().each( function ( d, j ) {
                if(d != '') $selectField.append( '<option value="'+d+'">'+d+'</option>' )
              } );
              $columnInputContainer.empty().append( $selectField );
              $columnInputContainer.find('select').on( 'change', function () {
                var val = $.fn.dataTable.util.escapeRegex(
                  $(this).val()
                );
              column.search( val ? '^'+val+'$' : '', true, false ).draw();
              });
            }
          }
          else if( classMatch( datetimeFieldsClassnames, $(columnHeader) ) ){
            currentClassname = classMatch( datetimeFieldsClassnames, $(columnHeader) );
            popover =  '<button type="button" class="btn btn-default" data-container="body" data-toggle="popover" data-placement="top">Début/Fin</button>';
            popoverContent = '<form>';
            popoverContent +=  '<label for="table' + key + currentClassname.ucfirst() + columnIndex + 'BeginningDatetime">Date de début</label>';
            popoverContent +=  '<div class="input-group">';
            popoverContent +=    '<input type="datetime" class="form-control datetimepicker date_filter" id="table' + key + currentClassname.ucfirst() + columnIndex + 'BeginningDatetime" placeholder="Date de début">';
            popoverContent +=    '<div class="input-group-btn">';
            popoverContent +=      '<button type="button" class="btn btn-default clearfield"><span class="glyphicon glyphicon-remove"></span></button>';
            popoverContent +=    '</div>';
            popoverContent +=  '</div>'
            popoverContent +=  '<label for="table' + key + currentClassname.ucfirst() + columnIndex + 'EndingDatetime">Date de fin</label>';
            popoverContent +=  '<div class="input-group">';
            popoverContent +=    '<input type="datetime" class="form-control datetimepicker date_filter" id="table' + key + currentClassname.ucfirst() + columnIndex + 'EndingDatetime" placeholder="Date de fin">';
            popoverContent +=    '<div class="input-group-btn">';
            popoverContent +=      '<button type="button" class="btn btn-default clearfield"><span class="glyphicon glyphicon-remove"></span></button>';
            popoverContent +=    '</div>';
            popoverContent +=  '</div>'
            popoverContent += '</form>';
            $popoverContent = $(popoverContent);
            $popoverContent.find('.datetimepicker').on('dp.change', function(){
              $api.draw();
            })
            .datetimepicker({
              locale: 'fr',
              format: 'YYYY-MM-DD HH:mm'
            });

            $columnInputContainer.empty().append( popover );
            $columnInputContainer.find('button[data-toggle="popover"]').popover({
              html: true,
              content: $popoverContent
            });

          }
          else if( classMatch( dateFieldsClassnames, $(columnHeader) ) ){
            currentClassname = classMatch( dateFieldsClassnames, $(columnHeader) );
            popover =  '<button type="button" class="btn btn-default" data-container="body" data-toggle="popover" data-placement="top">Début/Fin</button>';
            popoverContent = '<form>';
            popoverContent +=  '<label for="table' + key + currentClassname.ucfirst() + columnIndex + 'BeginningDate">Date de début</label>';
            popoverContent +=  '<div class="input-group">';
            popoverContent +=    '<input type="text" class="form-control datepicker date_filter" id="table' + key + currentClassname.ucfirst() + columnIndex + 'BeginningDate" placeholder="Date de début">';
            popoverContent +=    '<div class="input-group-btn">';
            popoverContent +=      '<button type="button" class="btn btn-default clearfield"><span class="glyphicon glyphicon-remove"></span></button>';
            popoverContent +=    '</div>';
            popoverContent +=  '</div>'
            popoverContent +=  '<label for="table' + key + currentClassname.ucfirst() + columnIndex + 'EndingDate">Date de fin</label>';
            popoverContent +=  '<div class="input-group">';
            popoverContent +=    '<input type="text" class="form-control datepicker date_filter" id="table' + key + currentClassname.ucfirst() + columnIndex + 'EndingDate" placeholder="Date de fin">';
            popoverContent +=    '<div class="input-group-btn">';
            popoverContent +=      '<button type="button" class="btn btn-default clearfield"><span class="glyphicon glyphicon-remove"></span></button>';
            popoverContent +=    '</div>';
            popoverContent +=  '</div>'
            popoverContent += '</form>';
            $popoverContent = $(popoverContent);
            $popoverContent.find('.datepicker').datetimepicker({
              locale: 'fr',
              format: 'YYYY-MM-DD'
            });
            $popoverContent.find('.datepicker').on('dp.change', function(){
              $api.draw();
            });

            $columnInputContainer.empty().append( popover );
            $columnInputContainer.find('button[data-toggle="popover"]').popover({
              html: true,
              content: $popoverContent
            });

          }
          else{
            $( 'input', $(this.header()).parent().siblings('.search').children()[columnIndex] ).on( 'keyup change', function () {
              if ( column.search() !== this.value ) {
                column
                  .search( this.value )
                  .draw();
              }
            });
          }
        });
        $datatable.show();

        $('.datatable_toolbar').each(function(){
          $toolbar = $(this);
          if( $toolbar.find('button.select-all').length < 1 )
            $toolbar.append('<button type="button" class="btn btn-default select-all" data-table-key="' + key + '">Sélectionner tout/Déselectionner tout</button>');
        });

        if(typeof errors_details !== 'undefined'){
          $.each(errors_details, function(){
            if( $datatable.attr('id') == this.html_element_id){
              if( this.accompanist_id == null ){
                $concernedRow = $('#'+this.html_element_id).find('tr[data-child-id="'+this.child_id+'"]');
              }
              else{
                $concernedRow = $('#'+this.html_element_id).find('tr[data-child-id="'+this.child_id+'"][data-accompanist-id="'+this.accompanist_id+'"]');
              }
              $concernedRow.effect("highlight", {}, 10000);
            }
          });
        }
      }
    });
  });

  //********************************************************************************************
  // clear button
  $(document).on('click', 'button.clearfield', function(){
    $(this).closest('.input-group').find('input').val('');
    $(this).closest('.input-group').find('input').trigger('change');
  });

  //end clear button
  //********************************************************************************************

  $('.dropdown-toggle').dropdown();
  // $('[data-toggle="popover"]').popover();
  $('.collapse').collapse()

  $('.datepicker,.datetimepicker').parent().css('position', 'relative');
  $('.datepicker').datetimepicker({
    locale: 'fr',
    format: 'YYYY-MM-DD'
  });

  $('.datetimepicker').datetimepicker({
    locale: 'fr',
    format: 'YYYY-MM-DD HH:mm'
  });
});